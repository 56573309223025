
import { defineComponent, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { GlobalDataProps } from '@/store'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'

interface LoginForm {
  name: string
  password: string | number
}

export default defineComponent({
  setup() {
    const router = useRouter()
    const store = useStore<GlobalDataProps>()
    const formRef = ref()
    const formData: LoginForm = reactive({
      name: '',
      password: ''
    })

    const rules = {
      name: [
        { required: true, message: '请输入用户名', trigger: 'blur' }, //trigger: 'change'
        { min: 3, message: '长度不小于 3 个字符', trigger: 'blur' }
      ],
      password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }]
    }

    const submitForm = () => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          const loadingInstance = ElLoading.service({
            text: '登录中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.8)'
          })
          const payload = {
            userName: formData.name,
            password: formData.password,
            type: 1
          }
          store
            .dispatch('loginAndFetch', payload)
            .then(data => {
              console.log(data)
              ElMessage.success('登录成功')
              loadingInstance.close()
              //设置了HttpOnly属性，js无法读取到cookie信息
              router.push('/')
            })
            .catch(() => {
              formRef.value.resetFields()
            })
        }
      })
    }
    return { formRef, formData, rules, submitForm }
  }
})
